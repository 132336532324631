//
//	Close
//

.btn-close {
	@include dark-mode {
		background: transparent escape-svg($dark-btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements

		color: $dark-btn-close-color;
	}
}
